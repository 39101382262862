<template>
  <v-card>
    <v-card-title>
      <v-btn
        class="mr-4"
        color="warning"
        outlined
        @click="exportCsv()"
      >
        <span class="ml-2">Exportar</span>
        <v-img
          class="ml-2"
          :src="require('@/assets/download-icon.svg')"
        />
      </v-btn>

      <v-spacer />

      <span>Relatório de retenção</span>
      <!-- Relatório de retenção - {{ reference }} - {{ statusSales }} - {{ vendorName }} -->

      <v-spacer />

      <v-icon @click="$emit('close')">
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>

    <div class="container-infos-bonification">
      <div class="card-infos-vendor">
        <div class="infos-line-vendor">
          <v-icon @click="$emit('close')">
            {{ icons.mdiFlagVariantOutline }}
          </v-icon>

          <span class="ml-2">Safra: {{ reference }}</span>
        </div>

        <div class="infos-line-vendor">
          <v-icon @click="$emit('close')">
            {{ icons.mdiAccountOutline }}
          </v-icon>

          <span class="ml-2">Consultor: {{ vendorName }}</span>
        </div>

        <div class="infos-line-vendor">
          <v-icon @click="$emit('close')">
            {{ icons.mdiFileCertificateOutline }}
          </v-icon>

          <span class="ml-2">Contratos: {{ footer.contracts }}</span>
        </div>

        <div class="infos-line-vendor">
          <v-icon @click="$emit('close')">
            {{ icons.mdiPercentOutline }}
          </v-icon>

          <span class="ml-2">Parcela: {{ footer.calculo }}</span>
        </div>
      </div>

      <div class="card-infos-bonification">
        <div

          class="confirm-payment"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                v-show="Number(average ? average.total : 0) > Number(footer.total)"
                v-bind="attrs"
                color="success"
                v-on="on"
              >
                {{ icons.mdiCash }}
              </v-icon>
            </template>
            <span>Valor a ser pago</span>
          </v-tooltip>
        </div>

        <div class="aling-line">
          <div class="badge-rounded">
            <v-icon color="info">
              {{ icons.mdiUmbrellaOutline }}
            </v-icon>
          </div>
          <span class="ml-2">Totais bonificação de férias (média)</span>
        </div>

        <div class="mt-2 infos">
          <span> 2° Parcela: {{ Number(average ? average.parcel_2 : 0 ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
          <span> 5° Parcela: {{ Number(average ? average.parcel_5 : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
          <span> Total: {{ Number(average ? average.total : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
        </div>
      </div>

      <div class="card-infos-bonification">
        <div

          class="confirm-payment"
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon
                v-show="Number(footer.total) > Number(average ? average.total : 0)"
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiCash }}
              </v-icon>
            </template>
            <span>Valor a ser pago</span>
          </v-tooltip>
        </div>

        <div class="aling-line">
          <div
            class="badge-rounded"
          >
            <v-icon color="warning">
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </div>
          <span class="ml-2">Totais bonificação</span>
        </div>

        <div class="mt-2 infos">
          <span> 2° Parcela: {{ Number(footer.parcel_2 ? footer.parcel_2 : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
          <span> 5° Parcela: {{ Number(footer.parcel_5 ? footer.parcel_5 : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
          <span> Total: {{ Number(footer.total ? footer.total : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</span>
        </div>
      </div>
    </div>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="reports"
        disable-sort
      >
        <template
          #[`item.contract_status`]="{item}"
        >
          <v-chip
            text
            small
            :class="`v-chip-light-bg ${returnColorStatus(item)}--text font-weight-semibold mt-1`"
          >
            {{ item.contract_status }}
          </v-chip>
        </template>
        <template #[`item.proposal_number`]="{item}">
          {{ item.proposal_number }} {{ item.proposal_digit }}
        </template>
        <template #[`item.parcel_2`]="{item}">
          <span>
            {{ formatParcelValue(item.parcel_2) }}
          </span>
        </template>
        <template #[`item.parcel_5`]="{item}">
          <span>
            {{ formatParcelValue(item.parcel_5) }}
          </span>
        </template>
        <template #[`item.commission_value2`]="{item}">
          <span>
            {{ formatParcelValue(item.commission_value2) }}
          </span>
        </template>
        <template #[`item.commission_value5`]="{item}">
          <span>
            {{ formatParcelValue(item.commission_value5) }}
          </span>
        </template>
        <template #[`item.total`]="{item}">
          <span>
            {{ formatParcelValue(item.total) }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiAlertDecagram,
  mdiCash,
  mdiCheckDecagram, mdiClose,
  mdiCurrencyUsd,
  mdiDownload,
  mdiFileCertificateOutline,
  mdiFlagVariantOutline, mdiPercentOutline, mdiUmbrellaOutline,
} from '@mdi/js'
import { saveAs } from 'file-saver'

const ExcelJS = require('exceljs')

export default {
  props: {
    reference: {
      type: String,
      required: true,
    },
    cpfVendor: {
      type: String,
      required: true,
    },
    vendorName: {
      type: String,
      required: true,
    },
    average: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiAlertDecagram,
        mdiCheckDecagram,
        mdiClose,
        mdiDownload,
        mdiFlagVariantOutline,
        mdiAccountOutline,
        mdiPercentOutline,
        mdiFileCertificateOutline,
        mdiCash,
        mdiUmbrellaOutline,
        mdiCurrencyUsd,
      },
      reports: [],
      footer: [],
      headers: [
        { text: 'CLIENTE', value: 'client_name' },
        { text: 'PROPOSTA', value: 'proposal_number' },
        { text: 'STATUS', value: 'contract_status', align: '' },
        { text: 'PARC. PAG. CLIENTE', value: 'parcel', align: 'center' },
        { text: '2ª PARC', value: 'parcel_2', align: 'center' },
        { text: '5ª PARC', value: 'parcel_5', align: 'center' },
        { text: 'BÔNUS 2° PARCELA', value: 'commission_value2', align: 'center' },
        { text: 'BÔNUS 5° PARCELA', value: 'commission_value5', align: 'center' },
        { text: 'TOTAL', value: 'total', align: 'center' },
      ],
    }
  },

  computed: {

  },

  created() {
    this.getItems()
  },

  methods: {
    formatParcelValue(value) {
      const valueToNumber = Number(value)

      return valueToNumber <= 0 ? 'R$ 0,00' : valueToNumber.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },

    returnColorStatus(item) {
      const status = item.contract_status

      switch (status) {
        case 'PRE-CONTEMPLADO':
          return 'warning'
        case 'PRE-CANCELADO':
          return 'purple'
        case 'CANCELADO':
        case 'CANCELADO NO GRUPO':
          return 'error'
        case 'NORMAL':
          return 'info'
        case 'CONTEMPLADO':
          return 'success'
        default:
          return ''
      }
    },

    getItems() {
      const body = {
        reference: this.reference,
        vendor_cpf: this.cpfVendor,
      }

      axiosIns.post('/api/v1/integration/contract/detailed_retention_report', body)
        .then(res => {
          this.reports = res.data.data.tableBody

          // eslint-disable-next-line prefer-destructuring
          this.footer = res.data.data.tableFooter[0]
        })
    },

    async exportCsv() {
      const file = await this.getCsv()
      if (file) {
        saveAs(file)
      }
    },

    async getCsv() {
      this.loaderExport = true

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'CLIENTE', key: 'client_name' },
        { header: 'PROPOSTA', key: 'proposal_number' },
        { header: 'DIGITO', key: 'proposal_digit' },
        { header: 'GRUPO COTA R D', key: 'group' },
        { header: 'STATUS', key: 'contract_status' },
        { header: 'N° PARCELA', key: 'parcel' },
        { header: 'REFERÊNCIA', key: 'reference' },
        { header: 'R$ PARCELA', key: 'parcel_value' },
        { header: '2ª PARC BÔNUS', value: 'commission_value2' },
        { header: '5ª PARC BÔNUS', value: 'commission_value5' },
        { header: 'QTD. PARC. PAG. CLIENTE', value: 'parcel', align: 'center' },
        { header: '2° PARCELA', key: 'parcel_2' },
        { header: '5° PARCELA', key: 'parcel_5' },
        { header: 'TOTAL', key: 'total' },
      ]

      this.reports.map(item => {
        worksheet.addRow([
          item.client_name,
          item.proposal_number,
          item.proposal_digit,
          item.group,
          item.contract_status,
          Number(item.parcel),
          item.reference,
          Number(item.parcel_value),
          Number(item.commission_value2) <= 0 ? 0 : Number(item.commission_value2),
          Number(item.commission_value5) <= 0 ? 0 : Number(item.commission_value5),
          item.parcel <= 0 ? 0 : item.parcel,
          Number(item.parcel_2) <= 0 ? 0 : Number(item.parcel_2),
          Number(item.parcel_5) <= 0 ? 0 : Number(item.parcel_5),
          Number(item.total),
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'dados_bonificação', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })

      this.loaderExport = false

      return blob
    },
  },
}
</script>
<style>
.fixed-footer {
  position: sticky;
  bottom: 0;
}
.footer{
  height: 50px;
}
.badge{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 40px;
}
.contracts{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  height: 50px;
  width: 100%;
}

.zeroed{
  color: #C34551;
  font-weight: 700;
}

.container-infos-bonification{
  padding: 15px 30px;
  gap: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infos-line-vendor{
  margin-bottom: 8px;

  display: flex;
  align-items: center;
}

.card-infos-vendor{
  width: 33%;
  font-size: 13px;
}

.card-infos-bonification{
  width: 33%;
  height: 110px;
  background: #3B355A;
  border-radius: 5px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.badge-rounded{
  display: flex;
  align-items: center;
  justify-content: center;

  background: #E7E3FC1A;
  opacity: 0.9;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.aling-line{
  display: flex;
  align-items: center;
}

.confirm-payment{
  display: flex;
  justify-content: flex-end;
}

.card-payment{
  background: #3B355A;
}

.infos{
  font-size: 13px;
}
</style>
