<template>
  <v-card>
    <v-card-title class="justify-center mb-4">
      <v-spacer />
      <span>Filtro Bonificação</span>
      <v-spacer />
      <v-icon
        @click="closeModal()"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>

    <v-card-text>
      <div class="separator">
        <div
          class="label"
        >
          <span>Filtrar dados</span>
        </div>
      </div>

      <v-autocomplete
        v-model="branch"
        :items="listRegionsItems"
        label="Filial"
        class=""
        outlined
        dense
        md="2"
      />

      <v-autocomplete
        v-model="store"
        :items="listStore"
        item-text="fantasy_name"
        item-value="fantasy_name"
        label="Loja"
        outlined
        dense
      />
      <v-menu
        v-model="monthReference"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="monthRefeReferenceValue"
            label="Mês referência"
            hide-details
            readonly
            outlined
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="monthRefeReferenceValue"
          type="month"
          class="datePicker p-0"
          width="500px"
          locale="pt-BR"
        />
      </v-menu>

      <div
        class="vacation"
      >
        <v-switch
          v-model="vacation"
          color="purple"
        />

        <p>Consultores de Férias</p>
      </div>

      <div class="actions-filter">
        <v-btn
          color="error"
          outlined
          @click="clearFilter()"
        >
          <v-icon
            size="20"
            class="mr-2"
          >
            {{ icons.mdiFilterVariantRemove }}
          </v-icon>
          <span>Limpar</span>
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="getFilterData()"
        >
          <span v-if="!isLoading">Filtrar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiClose, mdiFilterVariantRemove } from '@mdi/js'
import moment from 'moment'
import 'moment/locale/pt-br'

export default {
  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      icons: {
        mdiClose,
        mdiFilterVariantRemove,
      },
      branch: '',
      store: '',
      listRegionsItems: ['IMPERATRIZ', 'AÇAILÂNDIA', 'GRAJAÚ'],
      listStore: [],
      monthReference: false,
      monthRefeReferenceValue: '',
      isLoading: false,
      vacation: false,
      reference: '',
    }
  },

  computed: {
    computedMonthReference() {
      return this.dateFormat(this.monthRefeReferenceValue)
    },
  },

  created() {
    this.getListStore()
  },

  // :rules="[validators.required]"

  methods: {
    clearFilter() {
      this.branch = ''
      this.store = ''
      this.monthRefeReferenceValue = ''
      this.vacation = false
    },

    async getListStore() {
      try {
        const response = await axiosIns.get('/api/v1/records/company/index')

        this.listStore = response.data.data
      } catch (error) {
        this.showMessage({
          title: 'Erro ao buscar a lista de lojas!',
          text: error,
          icon: 'error',
        })
      }
    },

    async getFilterData() {
      this.isLoading = true
      try {
        const body = {
          region: this.branch,
          reference: this.monthRefeReferenceValue,
          filial: this.store,
          vacation: this.vacation,
        }
        const response = await axiosIns.post('/api/v1/integration/contract/second_fourth_installment_listing', body)

        await this.getReference()

        this.sendFilteredData(response.data.data, body)
        this.closeModal()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async getReference() {
      const response = await axiosIns.get('/api/v1/integration/bonus_parameter/index')

      this.reference = response.data.data[0].bonus_payment_interval
    },

    sendFilteredData(data, body) {
      moment.locale('pt-br')

      const newReference = moment(body.reference, 'YYYY-MM').subtract(this.reference, 'months')

      const newBody = { ...body, newReference: newReference.format('MMM/YYYY').toUpperCase() }

      this.$emit('filteredData', data)
      this.$emit('filteredBody', newBody)
    },
  },
}
</script>
<style scoped>
  .separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-bottom: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.datePicker {
  width: 100%;
}

.vacation{
  display: flex;
  align-items: center;
}

.vacation p{
  margin-top: 12px;
}

.actions-filter{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
