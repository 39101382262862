import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":"invoice-list"}},[_c(VCardText,{staticClass:"d-flex"},[_c(VRow,{staticClass:"mt-1"},[_c('div',{staticClass:"ml-4 d-flex flex-column"},[_c('span',{staticClass:"mb-4"},[_vm._v("Bonificação")]),(_vm.itemsTable.length > 0)?_c('div',{staticClass:"d-flex items-center mb-2"},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarAccountOutline)+" ")]),_c('span',[_vm._v("REFERÊNCIA: "+_vm._s(_vm.computedFormatDateReference))])],1):_vm._e(),(_vm.itemsTable.length > 0)?_c('div',{staticClass:"d-flex items-center mb-2"},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFlagVariantOutline)+" ")]),_c('span',[_vm._v("SAFRA: "+_vm._s(_vm.bodyFilter.newReference))])],1):_vm._e()]),_c(VSpacer),_c(VTextField,{staticClass:"mr-2",attrs:{"label":"Buscar por nome","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.findFilterResult()}},model:{value:(_vm.filterData),callback:function ($$v) {_vm.filterData=$$v},expression:"filterData"}}),_c(VBtn,{staticClass:"mr-4 mb-4",attrs:{"color":_vm.itemsTable.length > 0 ? 'success' : 'gray',"outlined":""},on:{"click":function($event){_vm.modalFilter = true}}},[_c(VIcon,{attrs:{"size":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Filtro")])],1),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.itemsTable.length > 0),expression:"itemsTable.length > 0"}],staticClass:"mr-4 mb-4",attrs:{"color":"error","outlined":""},on:{"click":_vm.clearGrid}},[_c(VIcon,{attrs:{"size":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariantRemove)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Limpar")])],1),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.itemsTable.length > 0),expression:"itemsTable.length > 0"}],staticClass:"ml-2",attrs:{"color":"warning","outlined":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"ml-2"},[_vm._v("Exportar")]),_c(VImg,{staticClass:"ml-2",attrs:{"src":require('@/assets/download-icon.svg')}})],1)]}}])},[_c(VList,_vm._l((_vm.itemsListExport),function(item){return _c(VListItem,{key:item,attrs:{"link":""}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item)},on:{"click":function($event){return _vm.exportCsv(item)}}})],1)}),1)],1)],1)],1),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.loadingTable,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"container-vacation"},[_vm._v(" "+_vm._s(item.name)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.vacation),expression:"item.vacation"}],staticClass:"vacation"},[_vm._v(" Férias ")])])]}},{key:"item.cpf",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cpf ? _vm.formattedCpfNumber(item.cpf) : '')+" ")]}},{key:"item.parcel_2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.parcel_2 ? Number(item.parcel_2).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}},{key:"item.adiplencia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.adiplencia)+" % ")]}},{key:"item.parcel_5",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.parcel_5 ? Number(item.parcel_5).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total ? Number(item.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}},{key:"item.delivery",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.delivery ? Number(item.delivery).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.detailsItems(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalhar")])])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-data-container"},[_c('span',[_vm._v("Nenhum registro encontrado!")]),_c('p',[_vm._v("Por favor, verifique os filtros de pesquisa.")]),_c(VImg,{attrs:{"width":"150","src":require("@/assets/no-data-image.svg")}})],1)]},proxy:true}],null,true)}),_c(VDialog,{attrs:{"max-width":"1600px","persistent":""},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('commission-quote-details',{key:(_vm.referenceToDetails, _vm.vendorCpfToDetails),attrs:{"reference":_vm.referenceToDetails,"cpf-vendor":_vm.vendorCpfToDetails,"vendor-name":_vm.nameVendor,"average":_vm.average},on:{"close":function($event){_vm.openModal = false}}})],1),_c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.modalFilter),callback:function ($$v) {_vm.modalFilter=$$v},expression:"modalFilter"}},[_c('BonificationsFilter',{on:{"close":function($event){_vm.modalFilter = false},"filteredData":function ($event) { return _vm.setTableData($event); },"filteredBody":function ($event) { return _vm.setBodyFilter($event); }}})],1),_c(VSnackbar,{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"#fff","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" X ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" POR FAVOR, selecione um filtro para realizar a exportação! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }