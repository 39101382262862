import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c(VBtn,{staticClass:"mr-4",attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.exportCsv()}}},[_c('span',{staticClass:"ml-2"},[_vm._v("Exportar")]),_c(VImg,{staticClass:"ml-2",attrs:{"src":require('@/assets/download-icon.svg')}})],1),_c(VSpacer),_c('span',[_vm._v("Relatório de retenção")]),_c(VSpacer),_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c('div',{staticClass:"container-infos-bonification"},[_c('div',{staticClass:"card-infos-vendor"},[_c('div',{staticClass:"infos-line-vendor"},[_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.icons.mdiFlagVariantOutline)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Safra: "+_vm._s(_vm.reference))])],1),_c('div',{staticClass:"infos-line-vendor"},[_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Consultor: "+_vm._s(_vm.vendorName))])],1),_c('div',{staticClass:"infos-line-vendor"},[_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileCertificateOutline)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Contratos: "+_vm._s(_vm.footer.contracts))])],1),_c('div',{staticClass:"infos-line-vendor"},[_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPercentOutline)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Parcela: "+_vm._s(_vm.footer.calculo))])],1)]),_c('div',{staticClass:"card-infos-bonification"},[_c('div',{staticClass:"confirm-payment"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(Number(_vm.average ? _vm.average.total : 0) > Number(_vm.footer.total)),expression:"Number(average ? average.total : 0) > Number(footer.total)"}],attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCash)+" ")])]}}])},[_c('span',[_vm._v("Valor a ser pago")])])],1),_c('div',{staticClass:"aling-line"},[_c('div',{staticClass:"badge-rounded"},[_c(VIcon,{attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiUmbrellaOutline)+" ")])],1),_c('span',{staticClass:"ml-2"},[_vm._v("Totais bonificação de férias (média)")])]),_c('div',{staticClass:"mt-2 infos"},[_c('span',[_vm._v(" 2° Parcela: "+_vm._s(Number(_vm.average ? _vm.average.parcel_2 : 0 ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})))]),_c('span',[_vm._v(" 5° Parcela: "+_vm._s(Number(_vm.average ? _vm.average.parcel_5 : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})))]),_c('span',[_vm._v(" Total: "+_vm._s(Number(_vm.average ? _vm.average.total : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})))])])]),_c('div',{staticClass:"card-infos-bonification"},[_c('div',{staticClass:"confirm-payment"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(Number(_vm.footer.total) > Number(_vm.average ? _vm.average.total : 0)),expression:"Number(footer.total) > Number(average ? average.total : 0)"}],attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCash)+" ")])]}}])},[_c('span',[_vm._v("Valor a ser pago")])])],1),_c('div',{staticClass:"aling-line"},[_c('div',{staticClass:"badge-rounded"},[_c(VIcon,{attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCurrencyUsd)+" ")])],1),_c('span',{staticClass:"ml-2"},[_vm._v("Totais bonificação")])]),_c('div',{staticClass:"mt-2 infos"},[_c('span',[_vm._v(" 2° Parcela: "+_vm._s(Number(_vm.footer.parcel_2 ? _vm.footer.parcel_2 : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})))]),_c('span',[_vm._v(" 5° Parcela: "+_vm._s(Number(_vm.footer.parcel_5 ? _vm.footer.parcel_5 : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})))]),_c('span',[_vm._v(" Total: "+_vm._s(Number(_vm.footer.total ? _vm.footer.total : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})))])])])]),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.reports,"disable-sort":""},scopedSlots:_vm._u([{key:"item.contract_status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{class:("v-chip-light-bg " + (_vm.returnColorStatus(item)) + "--text font-weight-semibold mt-1"),attrs:{"text":"","small":""}},[_vm._v(" "+_vm._s(item.contract_status)+" ")])]}},{key:"item.proposal_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.proposal_number)+" "+_vm._s(item.proposal_digit)+" ")]}},{key:"item.parcel_2",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatParcelValue(item.parcel_2))+" ")])]}},{key:"item.parcel_5",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatParcelValue(item.parcel_5))+" ")])]}},{key:"item.commission_value2",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatParcelValue(item.commission_value2))+" ")])]}},{key:"item.commission_value5",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatParcelValue(item.commission_value5))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatParcelValue(item.total))+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }